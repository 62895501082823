import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../service/navigate.service';
import { AppUserService } from '../../logic/services/appuserservice';
import { NotificationService } from '../../logic/services/notificationservice';


@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private userSer: AppUserService, private navServ: NavigationService, private notificationServ: NotificationService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.userSer.isLogin()) {
                // this.notificationServ.startPollingNotifications();
                resolve(true);
            }
            else {
                // this.notificationServ.stopPollingNotifications();
                resolve(false);
                this.navServ.redirectafterloginurl = state.url;
                this.navServ.route("/auth/login");
            }
        });
    }
}


@Injectable()
export class AnonymousGuardService implements CanActivate {
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!this.userservice.isLogin()) {
                // this.notificationServ.stopPollingNotifications();
                resolve(true);
            }
            else {
                resolve(false);
                this.navservice.route(this.userservice.defaultHomePageRoute());
            }
        });
    }

    constructor(private userservice: AppUserService, private navservice: NavigationService , private notificationServ: NotificationService) { }
}